import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Log } from "@services/log";
import { OrderfulInfo } from "@wearewarp/types/rest-api/third-party/edi/orderful"

@Component({
  selector: '[orderful-info]',
  templateUrl: './index.html',
  styleUrls: ['../../../dialogs/dialogs.scss', './index.scss']
})
export class OrderfulInfoDialog extends BaseFormDialog1 {
  protected formGroupDeclaration: FormGroupDeclaration = {
    reservationActionCode: { label: "Please choose accept or cancel", required: true },
  };
  order: any;
  inProgress = false;
  documents: Array<any> = [];
  thirdPartyInfo: OrderfulInfo;

  reservationActionCode = null;
  histories214: any[] = [];

  get orderId() {return this.order?.id}

  getRouterLink() {
    return [this.routeAdminShipmentList, this.order.id];
  }
  
  get isSend(): boolean {return !!this.thirdPartyInfo?.reservationActionCode}
  get failedAddress() {
    return this.order?.metadata?.shipments?.[0]?.deliveryInfos?.filter(item => !item?.addr?.metadata) || [];
  }
  get isInvoiced(): boolean {
    if (this.order.invoiceFileId || this.order.invoice) return true;
    return false;
  }
  get status990():string {
    const status = this.thirdPartyInfo?.reservationActionCode; 
    switch(status) {
      case Const.reservationActionCode.Accepted: return 'accepted';
      case Const.reservationActionCode.Cancelled: return 'canceled';
    }
  }
  get txtTitle() {
    if (this.isSend) {
      return `Order <code>${this.showOrderWarpId(this.getWarpId(this.order))}</code> has been ${this.status990}`;
    } else {
      return `Response To Orderful for order <code>${this.showOrderWarpId(this.getWarpId(this.order))}</code>`;
    }
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getThirdPartInfo();
  }

  onBtnOk() {
    if (!this.isSend) {
      this.action(Const.TransactionTypeNumber.TRANSACTION_990);
    } else {
      this.action(Const.TransactionTypeNumber.TRANSACTION_210);
    }
  }

  onBtnCancel() {
    this.closeDialog();
  }

  private getThirdPartInfo() {
    if (this?.order?.thirdPartyInfo) {
      this.thirdPartyInfo = this.order.thirdPartyInfo;
    } else {
      this.inProgress = true;
      let url = `${Const.APIURI_ORDERS}/${this.orderId}/third-party-info?source=${Const.thirdPartyOrderSource.orderful}`;
      this.api.GET(url, {}).subscribe(
        resp => {
          Log.d('thirdPartyInfo: ', resp);
          this.thirdPartyInfo = resp.data.info;
          this.inProgress = false;
        }, err => {
          this.showErr(err);
          this.inProgress = false;
        }
      );
    }
  }

  getTransactionHistories(event, businessNumber) {
    if (event.ctrlKey || event.metaKey) {
      let url = `p/getOrderfulTransactionList?businessNumber=${businessNumber}`;
      this.api.GET(url, {}).subscribe(
        resp => {
          Log.d('histories214: ', resp);
          this.histories214 = resp.data?.list_data ?? [];
        }, err => {
          this.showErr(err);
        }
      );
    }
  }

  private action(code) {
    this.inProgress = true;
    let url = `${Const.APIURI_ORDERS}/${this.orderId}/orderful-transaction`;
    let params: any = {};
    if (code == Const.TransactionTypeNumber.TRANSACTION_990) {
      let data: any = this.getFormData_JSON(true);
      params = {
        code,
        action: data.reservationActionCode,
      };
    } else if (code == Const.TransactionTypeNumber.TRANSACTION_210) {
      params = {
        code: Const.TransactionTypeNumber.TRANSACTION_210,
      };
    }
    this.api.POST(url, params).subscribe(
      resp => {
        if (resp.data?.thirdPartyInfo) {
          this.order.thirdPartyInfo = resp.data?.thirdPartyInfo;
          this.thirdPartyInfo = this.order.thirdPartyInfo;
          if (code == Const.TransactionTypeNumber.TRANSACTION_990) {
            if (params.action == Const.reservationActionCode.Cancelled) {
              this.order.status = Const.OrderStatus.canceled;
            }
          }
          if (resp.message) {
            this.showSuccess(resp.message);
          }
        }
        this.inProgress = false;
      }, err => {
        this.showErr(err);
        this.inProgress = false;
      }
    );
  }

}